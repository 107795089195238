import React from "react"

import Layout from "@narative/gatsby-theme-novela/src/components/Layout"
import Section from "@narative/gatsby-theme-novela/src/components/Section"
import SEO from "@narative/gatsby-theme-novela/src/components/SEO"
import Headings from "@narative/gatsby-theme-novela/src/components/Headings"
import { Link } from "gatsby"

import styled from "@emotion/styled"

import PricingTable, { PricingTableItem } from "@kiwicom/orbit-components/lib/PricingTable"
import Tooltip from "@kiwicom/orbit-components/lib/Tooltip"
import Badge from "@kiwicom/orbit-components/lib/Badge"
import Check from "@kiwicom/orbit-components/lib/icons/Check"
import Close from "@kiwicom/orbit-components/lib/icons/Close"
import List, { ListItem } from "@kiwicom/orbit-components/lib/List"
import Text from "@kiwicom/orbit-components/lib/Text"

import imgH1 from "../assets/sensor-h1.png"
import imgC1 from "../assets/sensor-c1.png"
import imgC2 from "../assets/sensor-c2.png"
import imgC3 from "../assets/sensor-c3.png"
import imgDash from "../assets/dash.png"

const Flex = styled.div`
    display: flex;
    flex-wrap: ${props => {
    if (props.wrapReverse) return 'wrap-reverse'
    else if (props.noWrap) return 'nowrap'
    return 'wrap'
  }};
    justify-content: ${props => {
    if (props.justifyContent) return props.justifyContent
    if (props.justifyCenter) return 'center'
    else if (props.justifyAround) return 'space-around'
    else if (props.justifyBetween) return 'space-between'
    else if (props.justifyEnd) return 'flex-end'
    return 'flex-start'
  }};
    align-items: ${props => {
    if (props.alignItems) return props.alignItems
    else if (props.alignStretch) return 'stretch'
    else if (props.alignEnd) return 'flex-end'
    if (props.alignCenter) return 'center'
    else if (props.alignBaseline) return 'baseline'
    return 'flex-start'
  }};
    align-content: ${props => {
    if (props.alignContent) return props.content
    else if (props.contentStart) return 'flex-start'
    else if (props.contentEnd) return 'flex-end'
    else if (props.contentCenter) return 'center'
    else if (props.contentBetween) return 'space-between'
    else if (props.contentAround) return 'contentAround'
    return 'stretch'
  }};
    flex-direction: ${props => (props.column ? 'column' : 'row')};
`

export const Column = styled.div`
    width: ${props => {
    if (props.three) return '33.33%'
    if (props.four) return '25%'
    return '50%'
  }};
    padding: ${props => (props.noPadding ? 0 : '15px')};
`

function produtos() {
  return (
    <Layout>
      <SEO />
      <Section>
        <div style={{ marginTop: "100px" }}>
          <Headings.h1>Produtos XSENSOR®</Headings.h1>
        </div>
        <div style={{ margin: "10px 0 40px 0" }}>
          <Headings.h3>A Escolha Profissional - Calibrados por artesãos</Headings.h3>
        </div>
      </Section>
      <Section>
        <Flex justifyAround alignCenter>
          <Link to="/h1" style={{ color:"#000", width: "350px", height:"350px",marginBottom: "30px" }}>
            <center><img src={imgH1} alt="xsensor"/></center>
            <p style={{ padding: "15px 10px 10px 10px"}}><strong>H1 - </strong>Sensor de Qualidade do Ar (TVOC)</p>
          </Link>
          <Link to="/c1" style={{ color:"#000", width: "350px", height:"350px",marginBottom: "30px" }}>
            <center><img src={imgC1} alt="xsensor"/></center>
            <p style={{ padding: "15px 10px 10px 10px"}}><strong>C1 - </strong>Sensor de temperatura e umidade</p>
          </Link>
          <Link to="/c2" style={{ color:"#000", width: "350px", height:"350px",marginBottom: "30px" }}>
            <center><img src={imgC2} alt="xsensor"/></center>
            <p style={{ padding: "15px 10px 10px 10px"}}><strong>C2 - </strong>Sensor de temperatura, umidade e sonda de temperatura externa</p>
          </Link>
          <Link to="/c3" style={{ color:"#000", width: "350px", height:"350px",marginBottom: "30px" }}>
            <center><img src={imgC3} alt="xsensor"/></center>
            <p style={{ padding: "15px 10px 10px 10px"}}><strong>C3 - </strong>Sensor de temperatura, umidade e termopar tipo K plug-n-play</p>
          </Link>
          <Link to="/dash" style={{ color:"#000", width: "350px", height:"350px",marginBottom: "30px" }}>
            <center><img src={imgDash} alt="xsensor"/></center>
            <p style={{ padding: "15px 10px 10px 10px"}}><strong>Dash - </strong>Plataforma de dados para gestão, monitoramento e análise de dados</p>
          </Link>
        </Flex>
      </Section>

      <Section style={{ marginTop: "100px" }}>
        <PricingTable defaultActiveElement={1} dataTest="PricingTable" >
          <PricingTableItem
            name="Operações em Movimento"
            priceBadge={<Badge type="info">Familia H</Badge>}
            mobileDescription="Pincipais funcionalidades:"
            onClick={null}>
            <List type="separated">
              <ListItem label="Bateria" icon={<Check />}>
                <Tooltip content="A longevidade da bateria está dependente da frequencia de ciclos de atualização dos sensores." stopPropagation>
                  <Text>1 ano</Text>
                </Tooltip>
              </ListItem>
              <ListItem label="Temperatura" icon={<Check />}>
                  <Text>Sim</Text>
              </ListItem>
              <ListItem label="Umidade" icon={<Check />}>
                <Text>Sim</Text>
              </ListItem>
              <ListItem label="IAQ" icon={<Check />}>
                  <Text>Sim</Text>
              </ListItem>
              <ListItem label="Acesso Global" icon={<Check />}>
                  <Tooltip content="Os nossos dispositivos funcionam com LPWAN Sigfox disponível globalmente. Conecção Wifi, 2G, 3G ou 4G não são necessárias" stopPropagation>
                    <Text>Sim</Text>
                  </Tooltip>
              </ListItem>
              <ListItem label="Alertas Automáticos" icon={<Check />}>
                  <Tooltip content="Alertas podem ser recebidos por Email, SMS" stopPropagation>
                    <Text>Sim</Text>
                  </Tooltip>
              </ListItem>
              <ListItem label="Relatórios Pré-Agendados" icon={<Check />}>
                  <Tooltip content="Relatórios diários, mensais ou anuais podem ser definidos para ser enviados por email em formato PDF" stopPropagation>
                    <Text>Sim</Text>
                  </Tooltip>
              </ListItem>
              <ListItem label="Software Multi-plataforma" icon={<Check />}>
                  <Tooltip content="Acesso aos dados pode ser feita por Computador Desktop ou Celular" stopPropagation>
                    <Text>Sim</Text>
                  </Tooltip>
              </ListItem>
              <ListItem label="Instação Sem Fios" icon={<Check />}>
                  <Text>Sim</Text>
              </ListItem>
            </List>
          </PricingTableItem>
          <PricingTableItem
            name="Operações Estáticas"
            priceBadge={<Badge type="warning">Familia C</Badge>}
            mobileDescription="Pincipais funcionalidades:"
            onClick={null}>
            <List type="separated">
              <ListItem label="Bateria" icon={<Check />}>
                <Tooltip content="A longevidade da bateria está dependente da frequencia de ciclos de atualização dos sensores." stopPropagation>
                  <Text>2 anos</Text>
                </Tooltip>
              </ListItem>
              <ListItem label="Temperatura" icon={<Check />}>
                  <Text>Sim</Text>
              </ListItem>
              <ListItem label="Umidade" icon={<Check />}>
                <Text>Sim</Text>
              </ListItem>
              <ListItem label="GPS" icon={<Close />}>
                  <Text>Não</Text>
              </ListItem>
              <ListItem label="Acesso Global" icon={<Check />}>
                  <Tooltip content="Os nossos dispositivos funcionam com LPWAN Sigfox disponível globalmente. Conecção Wifi, 2G, 3G ou 4G não são necessárias" stopPropagation>
                    <Text>Sim</Text>
                  </Tooltip>
              </ListItem>
              <ListItem label="Alertas Automáticos" icon={<Check />}>
                  <Tooltip content="Alertas pdoem ser recebidos por Email, SMS ou Whatsapp" stopPropagation>
                    <Text>Sim</Text>
                  </Tooltip>
              </ListItem>
              <ListItem label="Relatórios Pré-Agendados" icon={<Check />}>
                  <Tooltip content="Relatórios pré-definidos Diários, Mensais ou Anuais são enviados por email em formato PDF" stopPropagation>
                    <Text>Sim</Text>
                  </Tooltip>
              </ListItem>
              <ListItem label="Software Multi-plataforma" icon={<Check />}>
                  <Tooltip content="Acesso aos dados pode ser feita por Computador Desktop ou Celular" stopPropagation>
                    <Text>Sim</Text>
                  </Tooltip>
              </ListItem>
              <ListItem label="Instação Sem Fios" icon={<Check />}>
                  <Text>Sim</Text>
              </ListItem>
            </List>
          </PricingTableItem>
        </PricingTable>
      </Section>

    </Layout>
  );
}

export default produtos;
